import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import Seo from "../components/seo"
export default function CareerPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 46 }) {
        id
        content
        title
        positions {
          job {
            location
            title
            fileUpload {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const { content, positions } = data.wpPage
  const jobs = positions.job
  return (
    <Layout>
      <Seo title="Career" />
      <div className="max-w-2xl mx-auto py-16 md:py-32 px-4">
        <div className="text-center">
          <h2 className="text-2xl md:text-5xl font-bold leading-tight pb-16">
            Career - Join the team!
          </h2>
        </div>
        <div className="">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <div>
          {jobs.map(jobitem => {
            return <Job key={jobitem.title} jobitem={jobitem} />
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

function Job({ jobitem }) {
  console.log(jobitem)
  return (
    <div className="flex flex-col space-y-4 my-4">
      <p>Location: {jobitem.location}</p>
      <p>
        New position:{" "}
        <a href={jobitem.fileUpload.localFile.publicURL}>{jobitem.title}</a>
      </p>
    </div>
  )
}
